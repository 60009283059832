<template>
  <div>
    <NavBarLight></NavBarLight>
    <div class="container">
      <Loader v-if="loading"></Loader>
      <div v-else>
        <div v-if="hasError">
          <div class=" alert alert-danger  mt-3">
            {{errorMessage}}
          </div>
        </div>
        <div v-else>
          <h3 class="text-center">Sign-in:</h3>
          <p class="text-center">You need to sign-in to access videos of your tutorial</p>
        </div>
      </div>
      <div id="firebaseui-auth-container"></div>

    </div>
  </div>
</template>

<script>

import Loader from "@/components/Loader";
import firebase from "firebase/compat";
import "firebase/compat/auth"
import * as firebaseui from "firebaseui"
require("firebaseui/dist/firebaseui.css")
import NavBarLight from "@/components/NavBarLight";

export default {
  name: "Redeem",
  components: {NavBarLight, Loader},
  data(){
    return {
      jwt: this.$route.query.jwt,
      loading: false,
      hasError: false,
      errorMessage:""
    }
  },
  created() {
    this.loading = true
    console.log(this.jwt)
    if (!this.jwt){
      this.hasError = true
      this.errorMessage = "Error: the link you provided is invalid, can you click again on the link you received on your email"
      this.loading = false
      // empty jwt
      return
    }
    this.showLogin()
    this.loading=false
  },
  methods: {
    showLogin(){
      const uiConfig = {
        signInFlow: "popup",
        signInSuccessUrl : "/redeem/success?jwt="+this.jwt,
        signInOptions: [
          firebase.auth.EmailAuthProvider.PROVIDER_ID,
          firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        ],
      };
      let firebaseUiInstance = firebaseui.auth.AuthUI.getInstance()
      if (firebaseUiInstance) {
        firebaseUiInstance.start("#firebaseui-auth-container",uiConfig)
      } else {
        const ui = new firebaseui.auth.AuthUI(firebase.auth())
        ui.start("#firebaseui-auth-container",uiConfig)
      }
    }
  }

}
</script>

<style scoped>

</style>