<template>

  <nav class="navbar navbar-expand-lg navbar-light" style="background-color: #e3f2fd;">
    <a class="navbar-brand" href="#" >Practical Go Lessons</a>
  </nav>

</template>

<script>
export default {
  name: "NavBarLight"
}
</script>

<style scoped>

</style>